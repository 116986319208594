.logos {
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  text-align: center;

  &__title {
    @media (--up_to_S) {
      @apply --text-headline;
    }
    @media (--M_and_up) {
      font-size: 2em;
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
  }

  & .logo {
    flex: 1 1 35%;
    padding: 5%;

    & img {
      width: 100%;
      height: auto;
    }
  }

  & .logo__2 {
    flex: 1 1 50%;
  }

  & .logo__3 {
    flex: 1 1 25%;
  }
}
